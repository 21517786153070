import { graphql } from 'gatsby';
import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import FilterableProductsList from '@/components/section/Product/FilterableProductsList';
import Seo from '@/components/seo';
import { categoryParentsFromCategory, mergeProductsAndPrices } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './category.module.scss';

const CategoryTemplate = ({ data, pageContext, ...props }) => {
  const { location } = props;
  const { data: contextData } = pageContext;
  const { category } = contextData;

  const products = mergeProductsAndPrices(data).allGoogleSpreadsheetProducts.edges.map(
    (e) => e.node,
  );
  const categories = data.allGoogleSpreadsheetCategoryTree.edges.map((e) => e.node);
  const breadcrumbs = [
    { url: `/${ROUTE_NAMES.PRODUCTS}`, name: 'Productos' },
    ...categoryParentsFromCategory(category.uuid, categories).map((e) => ({
      url: `/${ROUTE_NAMES.CATEGORIES}/${e.uuid}`,
      name: e.name,
    })),
  ];
  return (
    <>
      <Seo
        title={category.name}
        description={`Descubrí la selección de ${category.name} que Visciglio Audio & Instrumentos tiene para vos.`}
      />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FilterableProductsList title={category.name} products={products} location={location} />
      </section>
    </>
  );
};

export const pageQuery = graphql`
  query productsByCategory($categoryUuid: [String]) {
    allGoogleSpreadsheetProducts(
      filter: { isPublished: { eq: "TRUE" }, fields: { categories: { in: $categoryUuid } } }
    ) {
      edges {
        node {
          uuid
          title
          shortDescription
          brand
          isNew
          isFeatured
          isOffer
        }
      }
    }
    allGoogleSpreadsheetCategoryTree {
      edges {
        node {
          uuid
          name
          parentCategory
        }
      }
    }
  }
`;

export default CategoryTemplate;
